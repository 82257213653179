import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ArticleCard from '../components/ArticleCard';
import cleanCrumbLabels from '../utils/cleanCrumbLabels';

export default function AllArticles({ pageContext, location }) {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	return (
		<StaticQuery
			query={graphql`
				query AllArticlesQuery {
					allMdx(limit: 1000) {
						nodes {
							excerpt(pruneLength: 400)
							frontmatter {
								headline
								featuredImage {
									childImageSharp {
										gatsbyImageData(width: 240, placeholder: BLURRED, formats: AUTO)
									}
								}
							}
							slug
						}
					}
				}
			`}
			render={({ allMdx: { nodes } }) => (
				<Layout>
					<Seo title="All Articles Archives" description="Every single article we've published on beachwheelers.com" />
					<div className="o-wrapper">
						<div className="o-row-2">
							<h1 className="c-md-h1">All Articles</h1>
						</div>
						<div className="o-row-3 o-row-6-d">
							<Breadcrumb location={location} crumbSeparator=" / " crumbs={cleanCrumbLabels(crumbs)} />
						</div>
						<div className="o-row-3 o-row-6-d">
							<div className="c-article-card-wrapper">
								{nodes.map((post, i) => (
									<ArticleCard
										key={i}
										url={post.slug}
										title={post.frontmatter.headline}
										description={post.excerpt}
										img={post.frontmatter.featuredImage}
									/>
								))}
							</div>
						</div>
					</div>
				</Layout>
			)}
		/>
	);
}
